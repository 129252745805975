<template>
	<div class="">
		CafeAndResturants
	</div>
</template>


<script>
export default {
	data() {
		return {
			
			
		}
	},
	methods: {
		
	},
}
</script>

<style lang="scss" >
</style>
